import { useCallback, useState } from "react";
import { Home } from "../lib/types";
import ImageGallery from "./ImageGallery";
import GoogleMaps from "./GoogleMaps";

interface HomeInfoProps {
  name: string;
  value: any;
}

function HomeInfo(props: HomeInfoProps) {
  const { name, value } = props;
  return (
    <div className="flex flex-col items-start mx-2">
      <span className="font-bold text-2xl">{value}</span>
      <span className="font-light text-base">{name}</span>
    </div>
  );
}

interface HomeDetailsProps {
  home: Home;
}

export default function HomeDetails(props: HomeDetailsProps) {
  const { home } = props;

  return (
    <div className="flex justify-center items-top w-full mt-8">
      <ImageGallery home={home} />

      <div className="text-3xl">
        <h1 className="font-bold underline">
          {home.streetLine}, {home.city} {home.state}
        </h1>
        <div className="flex justify-center mt-4">
          <HomeInfo name="Beds" value={home.beds} />
          <HomeInfo name="Baths" value={home.baths} />
          {home.sqft && (
            <HomeInfo name="Sq Ft" value={Number(home.sqft).toLocaleString()} />
          )}
          {home.yearBuilt && (
            <HomeInfo name="Year Built" value={home.yearBuilt} />
          )}
          {home.lotSize && home.beds === 0 && (
            <HomeInfo
              name="Lot Size"
              value={Number(home.lotSize).toLocaleString()}
            />
          )}
          {home.soldDate && (
            <HomeInfo
              name="Sold On"
              value={new Date(home.soldDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            />
          )}
        </div>
        <div className="flex mt-2">
          <GoogleMaps latitude={home.latitude} longitude={home.longitude} />
          <div className="w-64 h-72 overflow-scroll mx-2 py-2 pr-2">
            <p className="text-sm text-left break-normal">
              {home.listingRemarks}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
