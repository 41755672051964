import { useCallback, useState } from "react";
import { BeatLoader } from "react-spinners";

interface GuessInputProps {
  onGuess: (guess: number) => void;
}

export default function GuessInput(props: GuessInputProps) {
  const { onGuess } = props;

  const [guess, setGuess] = useState("");
  const [isGuessing, setIsGuessing] = useState(false);

  const handleInputChange = useCallback(
    (event: any) => {
      const inputValue = event.target.value.replace(/\D/g, ""); // Allow only numbers
      const formattedValue = Number(inputValue).toLocaleString();
      setGuess(formattedValue);
    },
    [setGuess]
  );

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && guess.length !== 0 && guess !== "0") {
      if (isGuessing) {
        return;
      }
      setIsGuessing(true);
      onGuess(Number(guess.replace(/\D/g, "")));
    }
  };
  return (
    <div className="w-1/2">
      <div className="flex flex-col items-center">
        <h1 className="text-3xl">How much did it sell for?</h1>
        <input
          type="text"
          placeholder="Price"
          className="input input-bordered w-full max-w-xs my-2"
          value={"$" + guess}
          onChange={(e: any) => {
            handleInputChange(e);
          }}
          onKeyDown={handleKeyDown}
        />
        <button
          className="btn btn-accent my-2 text-4xl w-1/3 font-bold"
          disabled={guess.length === 0 || guess === "0"}
          onClick={() => {
            if (isGuessing) {
              return;
            }
            setIsGuessing(true);
            onGuess(Number(guess.replace(/\D/g, "")));
          }}
        >
          {isGuessing ? <BeatLoader /> : <span>Guess</span>}
        </button>
      </div>
    </div>
  );
}
