import React, { useEffect } from "react";
import "./App.css";
import Main from "./components/Main";

import { v4 as uuidv4 } from "uuid";

function App() {
  useEffect(() => {
    console.log(localStorage.getItem("userId"));
    if (localStorage.getItem("userId")) {
      return;
    }

    const uuid = uuidv4();
    // Remove dashes if needed
    const uuidWithoutDashes = uuid.replace(/-/g, "");

    localStorage.setItem("userId", uuidWithoutDashes);
  }, []);

  return (
    <div data-theme="synthwave" className="App h-screen">
      <Main />
    </div>
  );
}

export default App;
