import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import {
  Game,
  GameStatus,
  Home,
  HomeResource,
  UserResource,
  UserScoreResource,
} from "../lib/types";
import {
  createGame,
  getHighScore,
  getHighScores,
  getUser,
  makeGuess,
} from "../lib/api";
import HomeDetails from "./HomeDetails";
import { GridLoader } from "react-spinners";
import GuessInput from "./GuessInput";
import GuessResult from "./GuessResult";
import Header from "./Header";
import StartGame from "./StartGame";
import GameMetadata from "./GameMetadata";
import GameRecap from "./GameRecap";
import Leaderboard from "./Leaderboard";

export default function Main() {
  const [status, setStatus] = useState<GameStatus>("not started");
  const [game, setGame] = useState<Game | null>(null);
  const [guess, setGuess] = useState<number | null>(null);
  const [roundStartedAt, setRoundStartedAt] = useState<number | null>(null);
  const [user, setUser] = useState<UserResource | null>(null);
  const [highScore, setHighScore] = useState<UserScoreResource | null>(null);
  const [highScores, setHighScores] = useState<UserScoreResource[]>([]);

  const [showHighScores, setShowHighScores] = useState(false);

  const guessTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  console.log(game);

  const homeResource: HomeResource | null = useMemo(() => {
    if (!game) {
      return null;
    }

    if (status === "guessed") {
      return game.homes[game.guesses.length - 1];
    }

    if (status === "guessing") {
      return game.homes[game.currentRound - 1] ?? null;
    }
    return null;
  }, [game, game?.scores, status]);

  const home: Home | null = useMemo(() => {
    return homeResource && homeResource.data;
  }, [homeResource]);

  const guessHousePrice = useCallback(
    async (guess: number) => {
      if (!game || !homeResource) {
        return;
      }
      setGuess(guess);
      const updatedGame = await makeGuess(game.id, homeResource.id, guess);
      if (updatedGame) {
        setGame(updatedGame);
        setStatus("guessed");
      }
    },
    [game, homeResource]
  );

  const startGame = async (rounds: number, timeLimit: number) => {
    setShowHighScores(false);
    setGame(null);
    setGuess(null);
    setStatus("guessing");
    const game = await createGame(rounds, timeLimit);
    if (game) {
      setGame(game);
    }
  };

  const userScore = useMemo(() => {
    if (!game) {
      return 0;
    }

    return game.scores.reduce(
      (accumulator, currentValue) => accumulator + currentValue.score,
      0
    );
  }, [game]);

  useEffect(() => {
    const fetchHighScores = async () => {
      if (!showHighScores) {
        return;
      }

      if (guessTimeoutRef.current) {
        clearTimeout(guessTimeoutRef.current);
        guessTimeoutRef.current = null;
      }

      const hs = await getHighScores();
      if (hs) {
        setHighScores(hs);
      }
    };

    fetchHighScores();
  }, [showHighScores]);

  useEffect(() => {
    if (!game || status === "not started") {
      return;
    }

    if (status === "guessed") {
      setRoundStartedAt(null);
      return;
    }

    setRoundStartedAt(Date.now());
    guessTimeoutRef.current = setTimeout(() => {
      guessHousePrice(0);
    }, game.timeLimit * 1000);
  }, [game, status]);

  useEffect(() => {
    const fetchUser = async () => {
      setUser(await getUser());
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const fetchHighScore = async () => {
      if (status !== "done" || !user) {
        return;
      }

      if (highScore === null) {
        const hs = await getHighScore(user.id);
        if (hs) {
          setHighScore(hs);
        }
      }
    };

    fetchHighScore();
  }, [status]);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter" && status === "guessed") {
        setStatus(game?.guesses.length === game?.rounds ? "done" : "guessing");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [game, status]);

  return (
    <div>
      <Header
        setShowHighScores={() => {
          setShowHighScores(true);
        }}
      />
      {showHighScores && (
        <Leaderboard highScores={highScores} startGame={startGame} />
      )}
      {!showHighScores && (
        <div className="flex flex-col justify-center items-center h-auto">
          {status === "not started" && (
            <StartGame startGame={startGame} user={user} setUser={setUser} />
          )}

          {status === "done" && game && (
            <GameRecap
              game={game}
              onPlayAgain={() => startGame(5, 30)}
              highScore={highScore}
              setShowHighScores={() => setShowHighScores(true)}
            />
          )}

          {status === "guessing" && !home && (
            <div className="flex items-center h-screen">
              <GridLoader color="white" />
            </div>
          )}

          {status !== "done" && game && home && (
            <>
              <GameMetadata
                game={game}
                status={status}
                score={userScore}
                roundStartedAt={roundStartedAt}
              />
              <div className="w-full h-2">
                <div className="divider mx-32 my-1 h-2"></div>
              </div>
              <HomeDetails home={home} />
              <div className="w-full">
                <div className="divider mx-32"></div>
              </div>
            </>
          )}

          {status == "guessing" && homeResource && home && game && (
            <GuessInput
              onGuess={async (guess: number) => {
                if (guessTimeoutRef.current) {
                  clearTimeout(guessTimeoutRef.current);
                }

                guessHousePrice(guess);
              }}
            />
          )}

          {status === "guessed" && game && home && guess !== null && (
            <>
              <GuessResult
                home={home}
                guess={game.guesses[game.guesses.length - 1]}
              />
              <div className="mt-8">
                <button
                  className="btn btn-primary text-2xl"
                  onClick={() => {
                    setStatus(
                      game?.guesses.length === game?.rounds
                        ? "done"
                        : "guessing"
                    );
                  }}
                >
                  <span>
                    {game?.guesses.length === game?.rounds
                      ? "Game Recap"
                      : "Next House"}
                  </span>
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
