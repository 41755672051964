import { useEffect, useState } from "react";
import { Game, GameStatus } from "../lib/types";

interface GameMetadataProps {
  game: Game;
  status: GameStatus;
  score: number;
  roundStartedAt: number | null;
}

export default function GameMetadata(props: GameMetadataProps) {
  const { game, status, score, roundStartedAt } = props;

  const currentRound = game.currentRound;
  const rounds = game.rounds;
  const timeLimit = game.timeLimit;

  const [now, setNow] = useState(Date.now());

  let differenceInSeconds = null;

  if (roundStartedAt) {
    const differenceInMilliseconds = Math.abs(now - roundStartedAt);
    differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  }

  useEffect(() => {
    setNow(Date.now());
    setInterval(() => {
      setNow(Date.now());
    }, 300);
  }, [roundStartedAt]);

  return (
    <div className="flex w-2/3 h-16 font-bold text-lg justify-between">
      <div>
        <span className="text-accent">Score: </span>
        <span className="text-2xl">{score}</span>
      </div>

      <div className="text-6xl">
        {differenceInSeconds != null && (
          <span
            className={`text-6xl ${
              timeLimit - differenceInSeconds <= 5 && "text-red-500"
            } ${
              timeLimit - differenceInSeconds > 5 &&
              timeLimit - differenceInSeconds <= 10 &&
              "text-yellow-300"
            }`}
          >
            {Math.max(timeLimit - differenceInSeconds, 0)}
          </span>
        )}
      </div>

      <div>
        <span className="text-accent">Round: </span>
        <span className="text-2xl">
          {status === "guessing" ? currentRound : game.guesses.length}/{rounds}
        </span>
      </div>
    </div>
  );
}
