import { MdLeaderboard } from "react-icons/md";

interface HeaderProps {
  setShowHighScores: () => void;
}

export default function Header(props: HeaderProps) {
  const { setShowHighScores } = props;
  return (
    <div className="w-full h-12 flex justify-between bg-secondary py-1 px-4">
      <div
        className="cursor-pointer flex"
        onClick={() => (window.location.href = "/")}
      >
        <img
          src="/logo.webp"
          alt="My Logo"
          className="object-contain w-auto "
        />
        <span className="mt-auto mb-auto font-bold text-lg text-background">
          HomeGuessr
        </span>
      </div>
      <div
        className="cursor-pointer flex font-bold text-lg flex items-center"
        onClick={setShowHighScores}
      >
        <MdLeaderboard />
        <span className="mx-2">Leaderboard</span>
      </div>
    </div>
  );
}
