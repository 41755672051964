import { useEffect, useState } from "react";
import { GuessResource, Home } from "../lib/types";
import ProgressBar from "./ProgressBar";
import Confetti from "react-confetti";

const PERCENTAGE_DIFF_CONFETTI = 5;
const PERCENTAGE_DIFF_CLOSE = 10;

interface GuessResultProps {
  home: Home;
  guess: GuessResource | null;
}

export default function GuessResult(props: GuessResultProps) {
  const { home, guess } = props;

  const [confettiEnabled, setConfettiEnabled] = useState(false);

  const maxPrice = Math.max(home.price, guess?.guess_amount || 0);
  let actualPercentage = home.price / maxPrice;
  let guessedPercentage = (guess?.guess_amount || 0) / maxPrice;

  const maxPercent = 90;
  const minPercent = 0;
  const percentRange = maxPercent - minPercent;

  // Scale percentages to a range of 0-100
  actualPercentage = actualPercentage * percentRange + minPercent;
  guessedPercentage = guessedPercentage * percentRange + minPercent;

  const diffPercentage =
    (Math.abs(home.price - (guess?.guess_amount || 0)) / home.price) * 100;

  useEffect(() => {
    const diffPercentage =
      (Math.abs(home.price - (guess?.guess_amount || 0)) / home.price) * 100;

    if (diffPercentage <= PERCENTAGE_DIFF_CONFETTI) {
      setConfettiEnabled(true);
      setTimeout(() => {
        setConfettiEnabled(false);
      }, 10000);
    }
  }, [home, guess]);

  let resultColor = "";
  if (guess?.points) {
    if (guess?.points >= 80) {
      resultColor = "text-green-500";
    } else if (guess?.points >= 25) {
      resultColor = "text-yellow-300";
    } else {
      resultColor = "text-red-500";
    }
  }

  return (
    <div className="mt-8 w-1/2">
      {confettiEnabled && <Confetti recycle={false} numberOfPieces={1000} />}
      <div className="flex justify-center items-center ">
        <ProgressBar
          progress={actualPercentage}
          animationDuration={1.5}
          labels={[
            {
              value: guessedPercentage,
              label: `Your Guess: $${Number(
                guess?.guess_amount
              ).toLocaleString()}`,
              position: "top",
            },
            {
              value: actualPercentage,
              label: `Sale Price: $${Number(home.price).toLocaleString()}`,
              position: "bottom",
            },
          ]}
        />
      </div>
      <div className="mt-10 flex justify-center">
        <span className="text-xl">
          You were off by{" "}
          <span
            className={`font-bold text-2xl mr-2 ${resultColor}
           
            `}
          >
            $
            {Number(
              Math.abs(home.price - (guess?.guess_amount || 0))
            ).toLocaleString()}{" "}
            ({diffPercentage.toFixed(1)}%)
          </span>
        </span>
        <span className="text-3xl font-bold ml-2">
          <span className={`${resultColor}`}>+{guess?.points}</span> points
        </span>
      </div>
    </div>
  );
}
