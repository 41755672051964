import { Dispatch, SetStateAction, useState } from "react";
import { createGame } from "../lib/api";
import { Game, GameStatus, UserResource } from "../lib/types";
import UserDetails from "./UserDetails";

interface StartGameProps {
  startGame: (rounds: number, timeLimit: number) => Promise<void>;
  user: UserResource | null;
  setUser: (user: UserResource) => void;
}

export default function StartGame(props: StartGameProps) {
  const { startGame, user, setUser } = props;

  const [rounds, setRounds] = useState<number>(5);
  const [timeLimit, setTimeLimit] = useState<number | null>(30);

  return (
    <div className="flex flex-col justify-center h-96">
      <h1 className="text-5xl my-4 font-bold">Home Guesser</h1>
      {user && <UserDetails user={user} setUser={setUser} />}
      {/* <div className="flex flex-col">
        <div className="flex items-center justify-between font-bold">
          <span>Rounds</span>
          <div className="dropdown dropdown-hover dropdown-right">
            <div
              tabIndex={0}
              role="button"
              className="btn m-1 bg-secondary text-lg w-32"
            >
              {rounds}
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content menu bg-secondary rounded-box z-[1] w-24 p-2 shadow text-lg"
            >
              <li>
                <a onClick={() => setRounds(3)}>3</a>
              </li>
              <li>
                <a onClick={() => setRounds(5)}>5</a>
              </li>
              <li>
                <a onClick={() => setRounds(10)}>10</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex items-center justify-between font-bold">
          <span>Time Limit</span>
          <div className="dropdown dropdown-hover dropdown-right">
            <div
              tabIndex={0}
              role="button"
              className="btn m-1 bg-secondary text-lg w-32"
            >
              {timeLimit === 30 && "30s"}
              {timeLimit === 60 && "1m"}
              {timeLimit === null && "None"}
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content menu bg-secondary rounded-box z-[1] w-24 p-2 shadow text-lg"
            >
              <li>
                <a onClick={() => setTimeLimit(30)}>30s</a>
              </li>
              <li>
                <a onClick={() => setTimeLimit(60)}>1m</a>
              </li>
              <li>
                <a onClick={() => setTimeLimit(null)}>None</a>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <button
        className="btn btn-primary text-2xl"
        onClick={() => startGame(rounds, timeLimit || 30)}
      >
        Start Guessing!
      </button>
    </div>
  );
}
