import { UserScoreResource } from "../lib/types";
import { GiGoat } from "react-icons/gi";

function formatDate(isoString: string) {
  const date = new Date(isoString);
  const options = { month: "2-digit", day: "2-digit", year: "numeric" };
  return date.toLocaleDateString("en-US", options as any);
}

interface LeaderboardProps {
  highScores: UserScoreResource[];
  startGame: (rounds: number, timeLimit: number) => Promise<void>;
}

export default function Leaderboard(props: LeaderboardProps) {
  const { highScores, startGame } = props;
  return (
    <div className="w-full flex justify-center">
      <div className="w-1/3 my-8">
        <h1 className="font-bold text-3xl my-4">Leaderboard</h1>
        <table className="table text-lg">
          <thead>
            <tr>
              <th></th>
              <th>Score</th>
              <th>User</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {highScores.map((score, index) => {
              return (
                <tr>
                  <th className="flex items-center">
                    <span className="mr-2">{index + 1}</span>
                    {index === 0 && (
                      <GiGoat size="2rem" className="text-primary" />
                    )}
                  </th>
                  <td>{score.score}</td>
                  <td>{score.user.username || `User ${score.user.id}`}</td>
                  <td>{formatDate(score.created_at)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <button
          className="btn btn-primary text-2xl mt-4"
          onClick={() => startGame(5, 30)}
        >
          Start Guessing!
        </button>
      </div>
    </div>
  );
}
