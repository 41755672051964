import {
  Home,
  Game,
  GameResource,
  UserResource,
  UserScoreResource,
} from "./types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const fetchRandomHouse = async (): Promise<Home | null> => {
  try {
    const userId = localStorage.getItem("userId");
    const headers = { Authorization: `Bearer ${userId}` };
    const response = await fetch(`${baseUrl}/home/v1/random`, { headers });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data as Home;
  } catch (error) {
    console.error("Error fetching random house:", error);
    return null;
  }
};

export const createGame = async (
  rounds: number,
  timeLimit: number | null
): Promise<Game | null> => {
  try {
    const userId = localStorage.getItem("userId");
    const headers = {
      Authorization: `Bearer ${userId}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(`${baseUrl}/game/v1/create`, {
      headers,
      method: "POST",
      body: JSON.stringify({
        rounds,
        time_limit: timeLimit,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data: GameResource = await response.json();

    return {
      id: data.id,
      rounds: data.rounds,
      currentRound: data.current_round,
      timeLimit: data.time_limit,
      homes: data.homes,
      scores: data.scores,
      guesses: data.guesses,
    } as Game;
  } catch (error) {
    console.error("Error creating game:", error);
    return null;
  }
};

export const makeGuess = async (
  gameId: number,
  homeId: number,
  guess: number | null
): Promise<Game | null> => {
  try {
    const userId = localStorage.getItem("userId");
    const headers = {
      Authorization: `Bearer ${userId}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(`${baseUrl}/game/v1/${gameId}/guess`, {
      headers,
      method: "POST",
      body: JSON.stringify({
        home_id: homeId,
        price: guess,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data: GameResource = await response.json();

    return {
      id: data.id,
      rounds: data.rounds,
      currentRound: data.current_round,
      timeLimit: data.time_limit,
      homes: data.homes,
      scores: data.scores,
      guesses: data.guesses,
    } as Game;
  } catch (error) {
    console.error("Error making guess:", error);
    return null;
  }
};

export const getUser = async (): Promise<UserResource | null> => {
  try {
    const userId = localStorage.getItem("userId");
    const headers = {
      Authorization: `Bearer ${userId}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(`${baseUrl}/user/v1/user`, {
      headers,
      method: "GET",
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data: UserResource = await response.json();

    return data as UserResource;
  } catch (error) {
    console.error("Error fetching user:", error);
    return null;
  }
};

export const updateUser = async (
  userId: number,
  username: string
): Promise<UserResource | null> => {
  try {
    const uniqueId = localStorage.getItem("userId");
    const headers = {
      Authorization: `Bearer ${uniqueId}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(`${baseUrl}/user/v1/user/${userId}`, {
      headers,
      method: "PUT",
      body: JSON.stringify({
        username,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data: UserResource = await response.json();

    return data as UserResource;
  } catch (error) {
    console.error("Error updating user:", error);
    return null;
  }
};

export const getHighScore = async (
  userId: number
): Promise<UserScoreResource | null> => {
  try {
    const uniqueId = localStorage.getItem("userId");
    const headers = {
      Authorization: `Bearer ${uniqueId}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(
      `${baseUrl}/user/v1/user/${userId}/high-score`,
      {
        headers,
        method: "GET",
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data: UserScoreResource = await response.json();

    return data as UserScoreResource;
  } catch (error) {
    console.error("Error trying to get high score:", error);
    return null;
  }
};

export const getHighScores = async (): Promise<UserScoreResource[] | null> => {
  try {
    const uniqueId = localStorage.getItem("userId");
    const headers = {
      Authorization: `Bearer ${uniqueId}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(`${baseUrl}/user/v1/high-scores`, {
      headers,
      method: "GET",
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data: UserScoreResource[] = await response.json();

    return data as UserScoreResource[];
  } catch (error) {
    console.error("Error trying to get high score:", error);
    return null;
  }
};
