import { useEffect, useRef, useState } from "react";
import { UserResource } from "../lib/types";
import { updateUser } from "../lib/api";

interface UserDetailsProps {
  user: UserResource;
  setUser: (user: UserResource) => void;
}

export default function UserDetails(props: UserDetailsProps) {
  const { user, setUser } = props;

  const updateUsernameRef = useRef<NodeJS.Timeout | null>(null);

  const [username, setUsername] = useState(user.username || "");

  useEffect(() => {
    const updateUsername = async () => {
      if (!username || username === user.username) {
        return;
      }

      if (updateUsernameRef.current) {
        clearTimeout(updateUsernameRef.current);
      }

      updateUsernameRef.current = setTimeout(async () => {
        const updatedUser = await updateUser(user.id, username);
        if (updatedUser) {
          setUser(updatedUser);
        }
      }, 500);
    };

    updateUsername();
  }, [username, user]);

  return (
    <div className="m-4">
      <label className="input input-bordered flex items-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="h-4 w-4 opacity-70"
        >
          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
        </svg>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          maxLength={20}
          className="grow"
          placeholder="Username"
        />
      </label>
    </div>
  );
}
