import { useEffect, useState } from "react";

type Label = {
  value: number;
  label: string;
  position: "top" | "bottom";
};

interface ProgressBarProps {
  progress: number;
  animationDuration: number;
  labels?: Label[];
}

export default function ProgressBar(props: ProgressBarProps) {
  const { progress, animationDuration, labels = [] } = props;
  const [style, setStyle] = useState({});

  const [progressAnimated, setProgressAnimated] = useState(0);
  const [showLabels, setShowLabels] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setProgressAnimated(progress);
    }, 500);
  }, [progress]);

  useEffect(() => {
    setShowLabels(false);

    setTimeout(() => {
      setShowLabels(true);
    }, 500 + animationDuration * 1000);
  }, []);

  useEffect(() => {
    setStyle({
      width: `${progressAnimated}%`,
      background:
        "linear-gradient(90deg, rgba(196,19,12,1) 0%, rgba(215,244,31,1) 70%, rgba(0,255,1,1) 100%)",
      transition: `width ${animationDuration}s cubic-bezier(.35,.88,.47,1.03)`,
    });
  }, [progressAnimated, animationDuration]);

  return (
    <div className="progress-bar-container">
      {labels.map((label) => {
        return (
          <div
            className={`absolute w-1 h-full bg-black transition-opacity duration-500 ${
              showLabels ? "opacity-100" : "opacity-0"
            }`}
            style={{ left: `${label.value}%` }}
          >
            <span
              className={`absolute w-max font-bold ${
                label.position === "top" ? "bottom-12" : "top-12"
              }`}
            >
              {label.label}
            </span>
          </div>
        );
      })}

      <div className="progress-bar" style={style} />
    </div>
  );
}
