import { Game, UserScoreResource } from "../lib/types";

interface GameRecapProps {
  game: Game;
  onPlayAgain: () => Promise<void>;
  highScore: UserScoreResource | null;
  setShowHighScores: () => void;
}

export default function GameRecap(props: GameRecapProps) {
  const { game, onPlayAgain, highScore, setShowHighScores } = props;

  return (
    <div className="font-bold mt-8">
      <h1 className="text-3xl mb-4">Game Over</h1>
      <h2 className="text-4xl mb-4">
        You scored{" "}
        <span className="text-green-500">{game.scores[0].score}</span> points
      </h2>
      {highScore && (
        <h3 className="text-2xl mb-4">
          High Score: <span className="text-green-500">{highScore?.score}</span>{" "}
          points
        </h3>
      )}
      <div className="m-4">
        <button className="btn btn-primary text-2xl mr-2" onClick={onPlayAgain}>
          Play Again
        </button>
        <button
          className="btn btn-accent text-2xl ml-2"
          onClick={setShowHighScores}
        >
          Leaderboard
        </button>
      </div>
      <div>
        <table className="table text-lg">
          <thead>
            <tr>
              <th></th>
              <th>Address</th>
              <th>Price</th>
              <th>Guess</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            {game.homes.map((home, index) => {
              return (
                <tr>
                  <th className="w-64">
                    <img
                      src={home.data.photos[0]}
                      className="object-contain"
                      referrerPolicy="no-referrer"
                    />
                  </th>
                  <td>
                    {home.data.streetLine}, {home.data.city} {home.data.state}
                  </td>
                  <td>${Number(home.data.price).toLocaleString()}</td>
                  <td>
                    ${Number(game.guesses[index].guess_amount).toLocaleString()}
                  </td>
                  <td>{game.guesses[index].points}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
