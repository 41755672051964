import { useEffect, useState } from "react";
import { Home } from "../lib/types";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

interface ImageGalleryProps {
  home: Home;
}

export default function ImageGallery(props: ImageGalleryProps) {
  const { home } = props;

  const [photoNum, setPhotoNum] = useState(0);

  useEffect(() => {
    setPhotoNum(0);
  }, [home]);

  return (
    <div className="flex flex-col items-center h-1/3">
      <div className="flex flex-row justify-center">
        <div className="mx-2 h-auto flex items-center">
          <button
            className="btn btn-circle btn-outline"
            onClick={() => {
              if (photoNum - 1 < 0) {
                setPhotoNum(home.photos.length - 1);
              } else {
                setPhotoNum(photoNum - 1);
              }
            }}
          >
            <IoIosArrowBack />
          </button>
        </div>

        <img
          src={home.photos[photoNum]}
          className="object-contain w-full h-96"
          referrerPolicy="no-referrer"
        />

        <div className="mx-4 h-auto flex items-center">
          <button
            className="btn btn-circle btn-outline"
            onClick={() => {
              if (photoNum + 1 >= home.photos.length) {
                setPhotoNum(0);
              } else {
                setPhotoNum(photoNum + 1);
              }
            }}
          >
            <IoIosArrowForward />
          </button>
        </div>
      </div>
      <div>
        <span>
          {photoNum + 1} / {home.photos.length}
        </span>
      </div>
    </div>
  );
}
